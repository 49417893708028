import { PAGES } from "./urls";

export const AUTH_ROUTES = [
  "/dashboard",
  "/finish-registration",
  "/dashboard",
  // "/newbrand",
  "/brand",
  "/create/text-to-image",
  "/create/photoshoot",
  // "/create/edit",
  "/magictool/ai-background-remover",
  "/magictool/magic-remover",
  "/magictool/text-remover",
  "/magictool/image-enhancer",
  "/magictool/image-hd-clarity",
  "/magictool/image-to-text",
  "/magictool/search-replace-object",
  "/magictool/color-changer",
  "/magictool/voice-to-image",
  "/animation/image-to-animation",
  "/animation/animation-feed",
  "/agency/clients",
  "/agency/add-client",
  "/agency/members",
  "/agency/invite-member",
  "/studio-creator",
  "/setting",
  "/assets",
  "/community",
  "/my-feed",
  "/logout",
  "/video-training",
];

export const NO_AUTH_ROUTES = [
  // Not used routes
  "/landing-page",
  "/register",

  // Active routes
  "/reset-password",
  "/accept-invitation",
  "/new-sign-up",
  "/admin",
  "/admin/login",
  PAGES.LOGIN.path,
  PAGES.FORGOT_PASSWORD.path,
  PAGES.PRICING.path,
  PAGES.FEATURES.path,
  PAGES.FAQS.path,
  PAGES.TERMS_OF_SERVICE.path,
  PAGES.BLOGS.path,
  PAGES.NEWS.path,
  PAGES.PRIVACY_POLICY.path,
  PAGES.CONTACT_US.path,
  // "/blog-detail",
  // "/news-details",
];

export const ADMIN_AUTH_ROUTES = [
  "/admin/dashboard",
  "/admin/users",
  "/admin/licenses",
  "/admin/logout",
  "/admin/settings",
  "/admin/templates",
  "/admin/testimonials",
  "/admin/blogs",
  "/admin/news",
  "/admin/privacypolicy",
  "/admin/termsofservice",
  "/admin/pricing",
  "/admin/feature",
  "/admin/faqs",
  "/admin/credits",
  "/admin/homepage",
  "/admin/statistics",
  "/admin/webinar",
  "/admin/logs",
];

export const LICENSE = {
  FREE_TRIAL: "FreeTrial",
  STARTER: "Starter",
  BUSINESS: "Business",
  PROFESSIONAL: "Professional",
  ANIMATION: "Animation",
  AGENCY: "Agency",
};
