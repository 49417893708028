import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { ICONS, IMAGES } from "assets";
import React from "react";
import { theme } from "utils/theme";

const TraditionalVsAiPowered = () => {
  const TRADITIONAL_POINTS = [
    "Hiring professional photographers and studio space",
    "Average turnaround time: 1-2 weeks",
    "Post-editing requires specialized software and skills",
    "Costly and time-consuming revisions or reshoots",
    "Limited to photographer's availability and schedule",
    "Requires physical handling and shipping of products to the studio",
  ];
  const MODERN_POINTS = [
    "Easy-to-use, web-based application",
    "Average time to produce a product photo: Minutes",
    "User-friendly interface, perfect for beginners",
    "Update or revise your product photos anytime no extra cost",
    "Consistent quality and style, customizable to your brand",
    "No shipping or physical handling perfect for digital-first businesses",
  ];

  return (
    <Stack
      sx={{
        py: 8,
        background:
          "linear-gradient(0deg, rgba(115, 113, 252, 0.23) 4%, rgba(177, 109, 246, 0.10) 95%)",
      }}
    >
      <Container maxWidth="xl">
        <Stack sx={{ gap: 8 }}>
          <Stack sx={{ alignItems: "center", gap: 1 }}>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.common.black,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Traditional Product Photography
            </Typography>
            <Typography
              variant="h4"
              sx={{ color: theme.palette.primary.extraDark, fontWeight: 600 }}
            >
              Vs
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                background: theme.palette.primaryGradient.main,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textAlign: "center",
              }}
            >
              Creativio’s AI-Powered Product Shots.
            </Typography>
          </Stack>
          <Stack
            sx={{
              bgcolor: "#fff2f2",
              border: "1px solid #f25353",
              borderRadius: 8,
              p: { xs: 3, sm: 4, md: 6 },
            }}
          >
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={6}>
                <Stack
                  sx={{ height: "100%", gap: 3, justifyContent: "center" }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.primary.extraDark,
                      fontWeight: 600,
                    }}
                  >
                    Traditional Product Photography
                  </Typography>
                  <Stack sx={{ gap: 2 }}>
                    {TRADITIONAL_POINTS.map((point) => {
                      return (
                        <Stack
                          sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <ICONS.CancelIcon
                            sx={{
                              color: theme.palette.error.main,
                              fontSize: 25,
                            }}
                          />
                          <Typography
                            variant="h6"
                            sx={{ color: theme.palette.common.black }}
                          >
                            {point}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Stack
                  sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={IMAGES.Traditional}
                    alt="Traditional"
                    sx={{ borderRadius: 5, width: "100%", maxWidth: "440px" }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Stack
            sx={{
              bgcolor: "#f4fbf6",
              border: "1px solid #5eba75",
              borderRadius: 8,
              p: { xs: 3, sm: 4, md: 6 },
            }}
          >
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={6}>
                <Stack
                  sx={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={IMAGES.ModernAi}
                    alt="Traditional"
                    sx={{ borderRadius: 5, width: "100%", maxWidth: "440px" }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Stack
                  sx={{ height: "100%", gap: 3, justifyContent: "center" }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.primary.extraDark,
                      fontWeight: 600,
                    }}
                  >
                    AI-Powered Product Shots with CreativioAI
                  </Typography>
                  <Stack sx={{ gap: 2 }}>
                    {MODERN_POINTS.map((point) => {
                      return (
                        <Stack
                          sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <ICONS.CheckCircleIcon
                            sx={{
                              color: theme.palette.success.main,
                              fontSize: 25,
                            }}
                          />
                          <Typography
                            variant="h6"
                            sx={{ color: theme.palette.common.black }}
                          >
                            {point}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Stack sx={{ gap: 3, alignItems: "center" }}>
            <Typography
              variant="h5"
              sx={{
                color: theme.palette.grey[700],
                textAlign: "center",
                maxWidth: 1200,
              }}
            >
              <Box component="span" sx={{ color: theme.palette.primary.dark }}>
                CreativioAI
              </Box>{" "}
              isn't designed to replace traditional product photography but
              offers a scalable, time-saving, and cost-efficient alternative.
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: theme.palette.grey[700],
                textAlign: "center",
                maxWidth: 1200,
              }}
            >
              Transform your product images effortlessly, whether you're a
              seasoned expert or an absolute beginner.
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default TraditionalVsAiPowered;
