import { Box, Container, Stack, Typography, Grid } from "@mui/material";
import {
  VISUAL_STORIES_BRANDS_IMAGES,
  VISUAL_STORIES_GRID_IMAGES,
} from "assets";
import React from "react";
import { theme } from "utils/theme";

const VisualStories = () => {
  const VISUAL_STORIES_GRID = [
    VISUAL_STORIES_GRID_IMAGES.GridImg1,
    VISUAL_STORIES_GRID_IMAGES.GridImg2,
    VISUAL_STORIES_GRID_IMAGES.GridImg3,
    VISUAL_STORIES_GRID_IMAGES.GridImg4,
  ];

  const VISUAL_STORIES_BRANDS = [
    VISUAL_STORIES_BRANDS_IMAGES.Amazon,
    VISUAL_STORIES_BRANDS_IMAGES.Facebook,
    VISUAL_STORIES_BRANDS_IMAGES.Shopify,
    VISUAL_STORIES_BRANDS_IMAGES.WooCommerce,
    VISUAL_STORIES_BRANDS_IMAGES.TikTok,
  ];

  return (
    <Container maxWidth="xl" sx={{ py: 8 }}>
      <Stack sx={{ gap: 5 }}>
        <Container maxWidth="md">
          <Stack sx={{ alignItems: "center", gap: 5 }}>
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              {VISUAL_STORIES_GRID.map((grindImage, index) => {
                return (
                  <Grid key={`Grid-${index}`} item xs={6} sm={6} md={3}>
                    <Box
                      component="img"
                      src={grindImage}
                      alt={`Grid-${index}`}
                      sx={{ borderRadius: 5, height: "100%", width: "100%" }}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.common.black,
                fontWeight: 600,
                maxWidth: 900,
                textAlign: "center",
              }}
            >
              Transform Your Product Into Iresistable Click Driven Sales
              Boosting{" "}
              <Box
                component="span"
                sx={{
                  background: theme.palette.primaryGradient.main,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Visual Stories
              </Box>{" "}
              Used On
            </Typography>
          </Stack>
        </Container>
        <Container maxWidth="lg">
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
              flexWrap: "wrap",
            }}
          >
            {VISUAL_STORIES_BRANDS.map((brandImage, index) => {
              return (
                <Box
                  key={`brand-${index}`}
                  component="img"
                  src={brandImage}
                  alt={`Grid-${index}`}
                  sx={{ height: "100%" }}
                />
              );
            })}
          </Stack>
        </Container>
      </Stack>
    </Container>
  );
};

export default VisualStories;
