import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { getApi } from "utils/customNetwork";
import throttle from "lodash/throttle";
import defaultImage from "assets/imgs/default/news_default.png";
import "./styles.scss";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { theme } from "utils/theme";
import moment from "moment";
import { lineClamp } from "utils/util";
import { ICONS } from "assets";
import { PAGES } from "utils/urls";
import LoadingScreen from "components/LandingComponents/loading-screen";
import { getLandingNews } from "store/slices/landingSite/reducer";
import { useSelector } from "react-redux";
import useDispatchWithAbort from "hooks/use-dispatch-with-abort";
import { LANDING_THEME } from "utils/landingTheme";

export function News() {
  // const { setLoading } = useContext(LayoutContext);
  // const [news, setNews] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const postsPerPage = 10; // Number of posts per page
  // const [isLast, setIsLast] = useState(false); // Flag to check if it's the last page

  // const [loading, setLoading] = useState();

  // const loadPosts = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const data = await getApi("/getNewsList", "POST", {
  //       page: currentPage,
  //       page_size: postsPerPage,
  //     });
  //     if (data.records !== undefined && data.records.length > 0) {
  //       setNews((prevPosts) => [...prevPosts, ...data.records]);
  //       setIsLast(data.last); // If `data.last` is true, no more posts to load
  //     }
  //   } catch (error) {
  //     console.error("Error fetching news:", error);
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [currentPage]);

  // useEffect(() => {
  //   setNews([]);
  //   setCurrentPage(1);
  // }, []);

  // useEffect(() => {
  //   loadPosts();
  // }, [currentPage, loadPosts]);

  // const handleScroll = throttle((e) => {
  //   if (isLast) return; // If it's the last page, no need to load more

  //   const { scrollTop, scrollHeight, clientHeight } = e.target.scrollingElement;
  //   const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

  //   if (scrollPercentage > 95) {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   }
  // }, 1000);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll); // Clean up event listener
  //   };
  // }, [handleScroll]);

  const [fetchData] = useDispatchWithAbort(getLandingNews);

  const {
    news: { data, loading },
  } = useSelector((state) => state.landingSite);

  useEffect(() => {
    fetchData({ page: 1, page_size: 20 });
  }, [fetchData]);

  if (loading && !data?.length) return <LoadingScreen />;

  const { HEADER_HEIGHT } = LANDING_THEME;

  return (
    <Stack
      sx={{
        background:
          "linear-gradient(0deg, rgba(115, 113, 252, 0.23) 4%, rgba(177, 109, 246, 0.10) 95%)",
        minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
      }}
    >
      <Container maxWidth="xl" sx={{ py: 8 }}>
        <Stack sx={{ gap: 10 }}>
          <Stack sx={{ gap: 3 }}>
            <Typography
              variant="h3"
              sx={{
                background: theme.palette.primaryGradient.main,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 600,
                fontSize: 45,
                textAlign: "center",
              }}
            >
              Explore Our Newsroom:
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.common.black,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Your Source for the Latest Updates, Insights, and Stories!
            </Typography>
          </Stack>

          {loading && !data?.length ? (
            <Stack
              sx={{
                minHeight: "calc(100vh - 450px)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <CircularProgress size="3rem" />
              ) : (
                <Typography
                  variant="h4"
                  sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
                >
                  No Data Found!
                </Typography>
              )}
            </Stack>
          ) : (
            <Grid container spacing={{ xs: 4 }}>
              {data?.map(
                ({ id, image, title, description, created_at }, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to={`${PAGES.NEWS.path}/${id}`}>
                        <Stack
                          sx={{
                            bgcolor: "white",
                            borderRadius: 3,
                            overFlow: "hidden",
                            boxShadow:
                              "0px 7px 38px 0px rgba(103, 110, 114, 0.10)",
                          }}
                        >
                          <Box
                            component="img"
                            src={image || defaultImage}
                            alt={title}
                            sx={{
                              width: "100%",
                              aspectRatio: "16/9",
                              objectFit: "cover",
                              borderRadius: 3,
                              // maxWidth: "500px",
                            }}
                          />
                          <Stack
                            sx={{
                              height: "100%",
                              // width: "100%",
                              gap: { xs: 2, sm: 2, md: 1.5 },
                              p: { xs: 2, sm: 2, md: 3 },
                            }}
                          >
                            <Stack sx={{ gap: 0.5 }}>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: theme.palette.grey[600],
                                  fontWeight: 600,
                                }}
                              >
                                {moment(created_at).format("MMM DD, YYYY")}
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{
                                  color: theme.palette.primary.extraDark,
                                  fontWeight: 600,
                                }}
                              >
                                {title}
                              </Typography>
                            </Stack>

                            <Box
                              dangerouslySetInnerHTML={{
                                __html: description,
                              }}
                              sx={{
                                width: "100%",
                                p: {
                                  color: theme.palette.common.black,
                                  ...lineClamp(3),
                                },
                              }}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              endIcon={<ICONS.ArrowForward />}
                              sx={{
                                alignSelf: "start",
                                textTransform: "none",
                                //   fontWeight: 600,
                                borderRadius: "30px",
                              }}
                            >
                              Read more
                            </Button>
                          </Stack>
                        </Stack>
                        {/* <Grid
                            key={id}
                            container
                            spacing={{ xs: 3, sm: 3, md: 5 }}
                            // sx={{ bgcolor: "red" }}
                          >
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={3.5}>
                              <Box
                                component="img"
                                src={image || DefaultImage}
                                alt={title}
                                sx={{
                                  width: "100%",
                                  aspectRatio: "16/9",
                                  objectFit: "cover",
                                  borderRadius: 3,
                                  // maxWidth: "500px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={8} xl={8.5}>
                              <Stack
                                sx={{
                                  height: "100%",
                                  width: "100%",
                                  justifyContent: "space-evenly",
                                  gap: { xs: 2, sm: 2, md: 1.5 },
                                }}
                              >
                                <Stack sx={{ gap: 0.5 }}>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: theme.palette.grey[600],
                                      fontWeight: 600,
                                    }}
                                  >
                                    {moment(created_at).format("MMM DD, YYYY")}
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    sx={{
                                      color: theme.palette.primary.dark,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {title}
                                  </Typography>
                                </Stack>
        
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: description,
                                  }}
                                  sx={{
                                    p: {
                                      color: theme.palette.common.black,
                                      ...lineClamp(6),
                                    },
                                  }}
                                />
                              </Stack>
                            </Grid>
                          </Grid> */}
                      </Link>
                    </Grid>
                  );
                }
              )}
            </Grid>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
