import { AdminLayout } from "components/layouts/AdminLayout";
import { CButton } from "components/atoms/CButton";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useState, useContext, useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import { getApi } from "utils/customNetwork";
import ConfirmationModal from "components/atoms/Modal/ConfirmationModal";
import "./styles.scss";
import { Box, Tooltip } from "@mui/material";
import { CommonTable } from "components/Table";
import moment from "moment/moment";
import { ICONS } from "assets";
import { COLORS } from "utils/theme";
import { commonSorting } from "utils/util";

export function AdminCredits() {
  const { setLoading, showNoti, checkToken } = useContext(LayoutContext);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [credits, setcredits] = useState([]);
  const [formData, setFormData] = useState({
    module: "",
    deduction_credit: "",
    id: null,
  });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [code, setCode] = useState(``);

  useEffect(() => {
    fetchCredits();
  }, []);

  const fetchCredits = async () => {
    try {
      setLoading(true);
      checkToken();
      const response = await getApi("/admin/getCredit");
      setcredits(response);
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");

    if (keys.length > 1) {
      setFormData((prevData) => {
        const newData = { ...prevData };
        let currentLevel = newData;
        keys.forEach((key, index) => {
          if (index === keys.length - 1) {
            currentLevel[key] = value;
          } else {
            currentLevel[key] = currentLevel[key] || {};
            currentLevel = currentLevel[key];
          }
        });
        return newData;
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let apiUrl = "/admin/createCredit";
    if (formData.id) {
      apiUrl = "/admin/updateCredit";
    }
    try {
      setLoading(true);
      checkToken();
      const response = await getApi(apiUrl, "POST", formData);
      toggleFormVisibility();
      if (formData.id) {
        showNoti("success", "Credit Updated Successfully!");
      } else {
        showNoti("success", "Credit Created Successfully!");
      }
      fetchCredits();
    } catch (e) {
      showNoti("error", e);
    } finally {
      setLoading(false);
    }
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
    setFormData({
      module: "",
      deduction_credit: "",
      id: null,
    });
    setCode("");
  };

  const handleTblEdit = (item) => {
    setFormData({
      module: item.module,
      deduction_credit: item.deduction_credit,
      id: item.id,
    });
    setIsFormVisible(true);
  };

  const confirmDeletion = async (item) => {
    setLoading(true);
    try {
      setLoading(true);
      checkToken();
      await getApi(`/admin/deleteCredit/${item?.id}`, "DELETE");
      showNoti("success", "Credit Deleted Successfully!");
      fetchCredits();
      setShowConfirmation(false);
      setItemToDelete(null);
    } catch (err) {
      showNoti("error", err);
    } finally {
      setLoading(false);
    }
  };

  const handleTblDelete = (item) => {
    setItemToDelete(item);
    setShowConfirmation(true);
  };

  const closeModal = () => {
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const ActionsContainer = ({ item }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Tooltip title="Edit">
          <ICONS.Edit
            onClick={() => handleTblEdit(item)}
            sx={{ color: COLORS.white, cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <ICONS.Delete
            onClick={() => handleTblDelete(item)}
            sx={{ color: COLORS.white, cursor: "pointer" }}
          />
        </Tooltip>
      </Box>
    );
  };

  const creditsColumns = [
    {
      id: "module",
      fieldName: "module",
      label: "Module",
      align: "center",
    },
    {
      id: "deduction_credit",
      fieldName: "deduction_credit",
      label: "Deduction Credit",
      align: "center",
    },
    {
      id: "updatedAt",
      fieldName: "updatedAt",
      label: "Updated On",
      render: ({ rowData }) =>
        moment(rowData?.updated_at).format("MMM, DD YYYY hh:mm A"),
      style: {
        display: "flex",
        justifyContent: "flex-end",
      },
      cellStyle: {
        minWidth: 180,
      },
      customSort: (dataA, dataB, order) => {
        return commonSorting(
          moment(dataA?.updated_at).format("MMM, DD YYYY hh:mm A"),
          moment(dataB?.updated_at).format("MMM, DD YYYY hh:mm A"),
          order
        );
      },
      align: "center",
    },
    {
      id: "id",
      fieldName: "id",
      label: "Actions",
      align: "center",
      render: (row) => {
        return <ActionsContainer item={row.rowData} />;
      },
      cellStyle: {
        width: 180,
        minWidth: 180,
      },
      sorting: false,
    },
  ];

  return (
    <AdminLayout
      page="dashboard"
      caption="Dashboard"
      message="Welcome back admin"
    >
      <div className="admin-credit body-container">
        <div className="head">
          <div className="header-credit">
            <Slide className="flex1">
              <h1>
                <span>Credits</span>
              </h1>
            </Slide>

            <div className="btn-group add-temp mt-15">
              <div className="btn-mode">
                {isFormVisible && (
                  <CButton
                    addClass="gradient"
                    active={true}
                    onClick={toggleFormVisibility}
                    disabled={!isFormVisible}
                  >
                    Go Back
                  </CButton>
                )}
              </div>
            </div>
          </div>
          {isFormVisible ? (
            <div className="mail-settings settings">
              <form onSubmit={handleSubmit}>
                <div className="form-group api-setting mt-20">
                  <label htmlFor="module">
                    Module:
                  </label>
                  <div className="input-with-icon ">
                    <input
                      type="text"
                      id="module"
                      name="module"
                      placeholder="Module"
                      value={formData.module}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group api-setting mt-20">
                  <label htmlFor="deduction_credit">Deduction Credit:</label>
                  <div className="input-with-icon ">
                  <input
                      type="number"
                      id="deduction_credit"
                      name="deduction_credit"
                      // class="deduction_credit"
                      placeholder="Deduction Credit"
                      value={formData.deduction_credit}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <button
                  className="custom-button primary active mt-25"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          ) : (
            <CommonTable
              columns={creditsColumns}
              rows={credits?.data}
              tableSearchEnable={true}
              searchableFields={["module", "deduction_credit"]}
              addHeaderButton={true}
              addHeaderButtonName={"Create Credit"}
              addHeaderButtonOnClick={toggleFormVisibility}
              addHeaderButtonDisabled={isFormVisible}
            />
          )}
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationModal
          isOpen={showConfirmation}
          onClose={closeModal}
          onConfirm={() => confirmDeletion(itemToDelete)}
          itemModule="CREDIT"
          itemName={itemToDelete ? itemToDelete?.module : ""}
        />
      )}
    </AdminLayout>
  );
}
