import React, { useState } from 'react';
import './styles.scss';

export function Tabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(tabs[0].label);
  const activeTabContent = tabs.find(tab => tab.label === activeTab)?.content;

  return (
    <div className="tabs">
      <ul className="tab-list">
        {tabs.map(tab => (
          <li
            key={tab.label}
            className={`tab-list-item ${tab.label === activeTab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.label)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
        {activeTabContent && (
          <div className="tab-content">
            <div>{activeTabContent}</div>
          </div>
        )}
        {/* <div className="tab-content">
          {tabs.map(tab => (
            tab.label === activeTab && tab.content ? <div key={tab.label}>{tab.content}</div> : null
          ))}
        </div> */}
    </div>
  );
}
