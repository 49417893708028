import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { IMAGES } from "assets";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { PAGES } from "utils/urls";

const Footer = () => {
  const theme = useTheme();

  const { pathname } = useLocation();

  const currentYear = new Date().getFullYear();
  const FOOTER_MENU_ITEMS = [
    PAGES.PRIVACY_POLICY,
    PAGES.TERMS_OF_SERVICE,
    PAGES.FAQS,
    PAGES.CONTACT_US,
  ];
  return (
    <Stack
      sx={{
        bgcolor: "#1F0C5A",
        position: "relative",
        background:
          "linear-gradient(30deg, rgba(31,12,90,1) 60%, rgba(118,0,152,1) 100%)",
        pt: 5,
        // "&::before": {
        //   content: '""',
        //   position: "absolute",
        //   width: "100%",
        //   height: "80px",
        //   backgroundColor: "white",
        //   clipPath: "polygon(100% 100%, 100% 0, 0 0);",
        //   top: -1,
        //   left: 0,
        // },
      }}
    >
      <Container maxWidth="xl">
        <Stack sx={{ py: 5, gap: 3 }}>
          <Stack>
            <Stack
              sx={{
                flexDirection: { md: "row" },
                justifyContent: "space-between",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Link to={PAGES.HOME.path} style={{ textDecoration: "none" }}>
                <Box component="img" src={IMAGES.LandingLogo} alt="logo" />
              </Link>
              <Stack
                sx={{
                  flexDirection: { sm: "row" },
                  alignItems: "center",
                  gap: 5,
                }}
              >
                {FOOTER_MENU_ITEMS.map(({ id, name, path }) => {
                  return (
                    <Link key={id} to={path} style={{ textDecoration: "none" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.common.white,
                          position: "relative",
                          "&::before": {
                            content: '""',
                            width: path === pathname ? "100%" : "0%",
                            height: "2px",
                            backgroundColor: "#7371FC",
                            display: "block",
                            transition: "width 0.5s",
                            position: "absolute",
                            left: 0,
                            bottom: -2,
                            borderRadius: "2px",
                          },
                          "&:hover::before": {
                            width: "100%",
                          },
                        }}
                      >
                        {name}
                      </Typography>
                    </Link>
                  );
                })}
              </Stack>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "36.5px",
                  background: theme.palette.primaryGradient.main,
                }}
              >
                Get Access Now
              </Button>
            </Stack>
          </Stack>
          <Divider sx={{ bgcolor: "#412A88" }} />
          <Stack sx={{ alignItems: "center" }}>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.grey[500] }}
            >
              © {currentYear}, Creativio. All rights reserved
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Footer;
