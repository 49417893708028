import React from "react";
import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LandingSiteProviders from "./components/LandingSiteProviders";
import { LANDING_THEME } from "utils/landingTheme";

export function LandingPageLayout() {
  const { HEADER_HEIGHT } = LANDING_THEME;

  return (
    <LandingSiteProviders>
      <Stack>
        <Header {...{ HEADER_HEIGHT }} />
        <Box
          sx={{
            marginTop: `${HEADER_HEIGHT}px`,
            minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
            bgcolor: "common.white",
          }}
        >
          <Outlet />
        </Box>
        <Footer />
      </Stack>
    </LandingSiteProviders>
  );
}
