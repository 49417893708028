export const PAGES = {
  LOGIN: {
    id: "login",
    path: "/login",
    name: "Login",
  },
  FORGOT_PASSWORD: {
    id: "forgot-password",
    path: "/forgot-password",
    name: "Forgot password",
  },
  HOME: {
    id: "home",
    path: "/",
    name: "Home",
  },
  FEATURES: {
    id: "features",
    path: "/features",
    name: "Features",
  },
  PRICING: {
    id: "Pricing",
    path: "/pricing",
    name: "Pricing",
  },
  BLOGS: {
    id: "blogs",
    path: "/blogs",
    name: "Blogs",
  },
  BLOG_DETAILS: {
    id: "blog-details",
    name: "Blogs Details",
  },
  NEWS: {
    id: "news",
    path: "/news",
    name: "News",
  },
  NEWS_DETAILS: {
    id: "news-details",
    name: "News Details",
  },
  PRIVACY_POLICY: {
    id: "privacy-policy",
    path: "/privacy-policy",
    name: "Privacy Policy",
  },
  TERMS_OF_SERVICE: {
    id: "terms-of-service",
    path: "/terms-of-service",
    name: "Terms of Service",
  },
  FAQS: {
    id: "faqs",
    path: "/faqs",
    name: "FAQS",
  },
  CONTACT_US: {
    id: "contact-us",
    path: "/contact-us",
    name: "Contact Us",
  },
};
