import React from "react";
import { CircularProgress, Button as MuiButton } from "@mui/material";

const Button = ({
  color = "primary",
  size = "medium",
  variant = "contained",
  loading = false,
  disabled = false,
  children,
  ...props
}) => {
  return (
    <MuiButton
      {...{ color, size, variant }}
      disabled={loading || disabled}
      {...(loading && {
        endIcon: <CircularProgress size={15} thickness={6} {...{ color }} />,
      })}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
