import { Container, Stack, Grid, Typography, Box } from "@mui/material";
import { KEY_FEATURES_IMAGES } from "assets";
import React from "react";
import { theme } from "utils/theme";

const KeyFeatures = () => {
  const FEATURES_LEFT = [
    {
      id: 1,
      title: "Turn Product Photos Into Breath-Taking Visuals!",
      image: KEY_FEATURES_IMAGES.AiMasterpiece,
    },
    {
      id: 2,
      title: "Get Studio-Quality Product Shots in 60 sec",
      image: KEY_FEATURES_IMAGES.StudioQuality,
    },
    {
      id: 3,
      title: "Enjoy Premium Features with a Single Payment",
      image: KEY_FEATURES_IMAGES.SinglePayment,
    },
  ];
  const FEATURES_RIGHT = [
    {
      id: 4,
      title: "Make Your Product Photos Shine Like Never Before",
      image: KEY_FEATURES_IMAGES.OneClickMagic,
    },
    {
      id: 5,
      title: "Save Time & Money On Expensive Photo Studios",
      image: KEY_FEATURES_IMAGES.MultipleIndustries,
    },
    {
      id: 6,
      title: "AI-Driven Product Photography Online Generator",
      image: KEY_FEATURES_IMAGES.AiHandle,
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ py: 8 }}>
      <Grid
        container
        spacing={5}
        sx={{ color: theme.palette.common.black, justifyContent: "center" }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ gap: 4 }}>
            {FEATURES_LEFT.map(({ id, title, image }) => {
              return (
                <Stack
                  key={id}
                  sx={{ flexDirection: "row", alignItems: "center", gap: 3 }}
                >
                  <Box
                    component="img"
                    src={image}
                    alt={title}
                    sx={{ maxHeight: "80px" }}
                  />
                  <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    {title}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack sx={{ gap: 4 }}>
            {FEATURES_RIGHT.map(({ id, title, image }) => {
              return (
                <Stack
                  key={id}
                  sx={{ flexDirection: "row", alignItems: "center", gap: 3 }}
                >
                  <Box
                    component="img"
                    src={image}
                    alt={title}
                    sx={{ maxHeight: "80px" }}
                  />
                  <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    {title}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default KeyFeatures;
