export const componentsStyle = (theme) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          textTransform: "capitalize",
        },
        contained: {},
        outlined: {},
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { fontWeight: 500 },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiDialogActions: { styleOverrides: { root: { padding: "16px 24px" } } },
    MuiAvatar: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {},
        head: {},
        body: {},
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        indeterminate: {},
      },
    },
  };
};
