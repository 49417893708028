import { useNavigate, Link } from "react-router-dom";
import { CButton } from "components/atoms/CButton";
import { useContext, useState } from "react";
import { getApi, setToken } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import "./styles.scss";
import logo from "assets/imgs/logo.svg";
import { ReactComponent as Man } from "assets/imgs/auth/people.svg";
import { ReactComponent as Lock } from "assets/imgs/auth/key.svg";
import { ReactComponent as Eye } from "assets/imgs/auth/eye.svg";
import { ReactComponent as EyeClose } from "assets/imgs/auth/eye_close.svg";
import { ReactComponent as Checked } from "assets/imgs/auth/checked.svg";
import { ReactComponent as UnChecked } from "assets/imgs/auth/unchecked.svg";
import SocialLinks from "./SocialLinks";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { IMAGES } from "assets";
import { Box, Container, Stack } from "@mui/material";

export function Login() {
  const navigate = useNavigate();
  const { setLoading, user, setUser, showNoti } = useContext(LayoutContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;
    setLoading(true);

    try {
      const data = await getApi("/login", "POST", { email, password });
      setToken(data.token);
      const expired = Math.floor(Date.now() / 1000) + 60 * 60; // 60 mins

      setUser({ user: data.user, token: data.token, expired });
      if (!!data.user.is_first_login) {
        navigate("/finish-registration");
      } else {
        navigate("/dashboard");
      }
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     setLoading(true);

  //     try {
  //       const res_data = await axios.get(
  //         "https://www.googleapis.com/oauth2/v3/userinfo",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${tokenResponse.access_token}`,
  //           },
  //         }
  //       );

  //       const request_param = {
  //         email: res_data.data.email,
  //         first_name: res_data.data.given_name,
  //         last_name: res_data.data.family_name,
  //         profile_img: res_data.data.picture,
  //         social_id: res_data.data.sub,
  //       };

  //       const data = await getApi("/google-login", "POST", request_param);
  //       setToken(data.token);
  //       const expired = Math.floor(Date.now() / 1000) + 60 * 60; // 60 mins

  //       setUser({ user: data.user, token: data.token, expired });

  //       if (!!data.user.is_first_login) {
  //         navigate("/finish-registration");
  //       } else {
  //         navigate("/dashboard");
  //       }
  //     } catch (err) {
  //       showNoti("error", err);
  //     }
  //     setLoading(false);
  //   },
  // });

  const checkUser = async () => {
    try {
      setLoading(true);
      setToken(user.token);
      const data = await getApi("/checkUser", "POST", {
        user_id: user.user.id,
      });
      const expired = Math.floor(Date.now() / 1000) + 10 * 60; // 10 mins
      setToken(data.token);
      setUser({ user: data.user, token: data.token, expired });
      if (data.user.is_first_login) {
        navigate("/finish-registration");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      navigate("/login");
    }
    setLoading(false);
  };

  useEffect(() => {
    // if user exists, call checkUser api
    if (user) {
      const expired = setUser.expired;
      const current = Math.floor(Date.now() / 1000);
      if (current - Number(expired) < 0) {
        navigate("/dashboard");
      } else {
        checkUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      id="#login"
      className="auth-area"
      sx={{ minHeight: "calc(100vh - 75px)" }}
    >
      <Helmet>
        <title>
          Creativio AI Login - Access AI Product Photography & Photo Editor
          Tools
        </title>
        <meta
          name="description"
          content="Sign in to Creativio AI for premium AI product photography, AI photoshoots, and advanced AI photo editor features. Elevate your photo quality now!"
        />
      </Helmet>
      <Container maxWidth="md" sx={{ pt: 5, pb: 3 }}>
        <Box
          className="login"
          style={{ background: `url(${IMAGES.BannerBg})` }}
        >
          <Stack
            sx={{ p: 3, width: "calc(100% - 48px)", alignItems: "center" }}
          >
            <Stack sx={{ width: "100%", maxWidth: "400px" }}>
              <Stack
                className="logo mt-15 d-flex justify-content-center"
                sx={{ flexDirection: "row", justifyContent: "center" }}
              >
                <img src={logo} alt="logo" />
              </Stack>
              <div className="active-area">
                <form onSubmit={handleSubmit}>
                  <div className="login-card mt-35">
                    <div className="form-group">
                      <div className="input-with-icon">
                        <Man className="icon-img" />
                        <input
                          type="email"
                          id="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group mt-25">
                      <div className="input-with-icon">
                        <Lock className="icon-img" />
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        {!showPassword ? (
                          <Eye
                            className="last-icon-img"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <EyeClose
                            className="last-icon-img"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group mt-15 remember-field">
                      <div
                        className="chk-remember flex txt-lbl cursor-pointer"
                        onClick={() => setRemember(!remember)}
                      >
                        {remember ? <Checked /> : <UnChecked />}&nbsp;Remember
                        Me
                      </div>
                      <Link
                        className="txt-lbl color-purple"
                        to="/forgot-password"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    <div className="mt-20">
                      <CButton
                        addClass="primary text-size-20 py-10 full large"
                        mainClass="w-full"
                        type="submit"
                      >
                        Sign In
                      </CButton>
                    </div>
                    {/* <div className="mt-10 text-center txt-lbl">
                    Don't have an account? &nbsp;
                    <Link
                      className="txt-lbl color-purple"
                      to="https://creativio.io/founders-special"
                    >
                      Sign Up Today
                    </Link>
                  </div> */}
                    {/* <div className="mt-20 line-field">
                    <LineFirst />
                    or
                    <LineLast />
                  </div> */}
                  </div>
                </form>
                {/* <div className="mt-15">
                <button
                  className="google-btn txt-lbl"
                  onClick={() => googleLogin()}
                >
                  <img src={icon_google} />
                  Continue with Google
                </button>
                div> */}
              </div>
            </Stack>
            <SocialLinks />
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
