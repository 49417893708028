import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getApi } from "utils/customNetwork";

export const getLandingFeatures = createAsyncThunk(
    "landingSite/getLandingFeatures",
    async (data, { rejectWithValue }) => {
        try {
            return await getApi("/getFeatresList", "POST", data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getLandingPricing = createAsyncThunk(
    "landingSite/getLandingPricing",
    async (data, { rejectWithValue }) => {
        try {
            return await getApi("/getPricingList", "POST", data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getLandingBlogs = createAsyncThunk(
    "landingSite/getLandingBlogs",
    async (data, { rejectWithValue }) => {
        try {
            return await getApi("/getBlogsList", "POST", data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getLandingNews = createAsyncThunk(
    "landingSite/getLandingNews",
    async (data, { rejectWithValue }) => {
        try {
            return await getApi("/getNewsList", "POST", data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const initialState = {
    features: {
        data: [],
        loading: false,
    },
    pricing: {
        data: [],
        loading: false,
    },
    blogs: {
        data: [],
        loading: false,
    },
    news: {
        data: [],
        loading: false,
    },
};

export const landingSiteSlice = createSlice({
    name: "landingSite",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLandingFeatures.pending, (state) => {
                state.features.loading = true;
            })
            .addCase(getLandingFeatures.fulfilled, (state, action) => {
                state.features.loading = false;
                const {
                    payload
                } = action;
                state.features.data = payload?.records || []
            })
            .addCase(getLandingFeatures.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.features.loading = false;
            });
        builder
            .addCase(getLandingPricing.pending, (state) => {
                state.pricing.loading = true;
            })
            .addCase(getLandingPricing.fulfilled, (state, action) => {
                state.pricing.loading = false;
                const {
                    payload
                } = action;
                state.pricing.data = payload?.records || []
            })
            .addCase(getLandingPricing.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.pricing.loading = false;
            });
        builder
            .addCase(getLandingBlogs.pending, (state) => {
                state.blogs.loading = true;
            })
            .addCase(getLandingBlogs.fulfilled, (state, action) => {
                state.blogs.loading = false;
                const {
                    payload
                } = action;
                state.blogs.data = payload?.records || []
            })
            .addCase(getLandingBlogs.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.blogs.loading = false;
            });
        builder
            .addCase(getLandingNews.pending, (state) => {
                state.news.loading = true;
            })
            .addCase(getLandingNews.fulfilled, (state, action) => {
                state.news.loading = false;
                const {
                    payload
                } = action;
                state.news.data = payload?.records || []
            })
            .addCase(getLandingNews.rejected, (state, action) => {
                if (axios.isCancel(action.payload)) {
                    return;
                }
                state.news.loading = false;
            });
    },
});

// export const { } = landingSiteSlice.actions;

export default landingSiteSlice.reducer;
