export const typography = (theme) => {
  return {
    fontFamily: [].join(","),
    h1: {
      overflowWrap: "break-word",
      fontWeight: 500,
    },
    h2: {
      overflowWrap: "break-word",
      fontWeight: 500,
      [theme.breakpoints.up("xs")]: {
        fontSize: "2.5rem",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "2.7rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2.9rem",
      },
    },
    h3: {
      overflowWrap: "break-word",
      fontWeight: 500,
      [theme.breakpoints.up("xs")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "2.3rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2.5rem",
      },
    },
    h4: {
      overflowWrap: "break-word",
      fontWeight: 500,
      [theme.breakpoints.up("xs")]: {
        fontSize: "1.6rem",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.8rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2rem",
      },
    },
    h5: {
      overflowWrap: "break-word",
      fontWeight: 500,
      [theme.breakpoints.up("xs")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.6rem",
      },
    },
    h6: {
      overflowWrap: "break-word",
      fontWeight: 500,
      [theme.breakpoints.up("xs")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.1rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.2rem",
      },
    },
    subtitle1: {
      overflowWrap: "break-word",
      fontWeight: 500,
    },
    subtitle2: {
      overflowWrap: "break-word",
      fontWeight: 500,
    },
    body1: {
      overflowWrap: "break-word",
      fontWeight: 500,
    },
    body2: {
      overflowWrap: "break-word",
      fontWeight: 500,
    },
  };
};
