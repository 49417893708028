import { useMemo } from "react";
import {
  BlogDetail,
  Blogs,
  ContactUs,
  Features,
  ForgotPassword,
  LandingPage,
  Login,
  News,
  NewsDetails,
  Pricing,
  PrivacyPolicy,
  TermsOfService,
} from "pages";
import { PAGES } from "utils/urls";
import { Faqs } from "pages/LandingPage/Faqs";

const useRoutes = () => {
  const routes = useMemo(
    () => [
      {
        ...PAGES.LOGIN,
        element: <Login />,
      },
      {
        ...PAGES.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        ...PAGES.HOME,
        element: <LandingPage />,
      },
      {
        ...PAGES.FEATURES,
        element: <Features />,
      },
      {
        ...PAGES.PRICING,
        element: <Pricing />,
      },
      {
        ...PAGES.BLOGS,
        element: <Blogs />,
      },
      {
        ...PAGES.BLOGS,
        element: <Blogs />,
      },
      {
        ...PAGES.BLOG_DETAILS,
        path: PAGES.BLOGS.path + "/:slug",
        element: <BlogDetail />,
      },
      {
        ...PAGES.NEWS,
        element: <News />,
      },
      {
        ...PAGES.NEWS_DETAILS,
        path: PAGES.NEWS.path + "/:id",
        element: <NewsDetails />,
      },
      {
        ...PAGES.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
      },
      {
        ...PAGES.TERMS_OF_SERVICE,
        element: <TermsOfService />,
      },
      {
        ...PAGES.FAQS,
        element: <Faqs />,
      },
      {
        ...PAGES.CONTACT_US,
        element: <ContactUs />,
      },
    ],
    []
  );

  return {
    routes,
  };
};

export default useRoutes;
