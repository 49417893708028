export const palette = {
  mode: "light",
  primary: {
    main: "#7371FC",
    dark: "#563bff",
    extraDark: "#36379B",
    darkest: "#1F0C5A",
  },
  primaryGradient: {
    main: "linear-gradient(91deg, #563BFF 1.01%, #7371FC 48.55%, #D94FD5 110.04%)",
  },
  secondary: {
    main: "#000000",
  },
  secondaryGradient: {
    main: "linear-gradient(90deg, #48D1CC 18.26%, #3CB371 80.31%)",
  },
  error: {
    main: "#e6533c",
    light: "#ffedee",
  },
  warning: {
    main: "#f5b849",
    light: "#ffefce",
  },
  info: {
    main: "#49b6f5",
  },
  success: {
    main: "#8CC640",
    light: "#8CC64033",
  },
};
