import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import MenuIcon from "@mui/icons-material/Menu";
import SubjectIcon from "@mui/icons-material/Subject";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { ReactComponent as ResetLoop } from "../assets/images/arrow-repeat.svg";
import { ReactComponent as Trash } from "../assets/images/trash.svg";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import LandingLogo from "./imgs/landing-website/landing-logo.svg";
import HeroBackground from "./imgs/landing-website/hero-background.webp";
import HeroElementLeft from "./imgs/landing-website/hero-element-left.webp";
import HeroElementRight from "./imgs/landing-website/hero-element-right.webp";
import BannerBg from "./imgs/landing-website/banner-bg.webp";
import BannerBgLarge from "./imgs/landing-website/banner-bg-large.webp";
import SocialProofPeople from "./imgs/landing-website/social-proof-people.png";
import Traditional from "./imgs/landing-website/traditional-vs-ai-powered/versus-traditional.webp";
import ModernAi from "./imgs/landing-website/traditional-vs-ai-powered/versus-modern-ai.webp.webp";
import Logo3d from "./imgs/landing-website/logo-3d.webp";

import AiHandle from "./imgs/landing-website/key-features/ai-handle.svg";
import AiMasterpiece from "./imgs/landing-website/key-features/ai-masterpiece.svg";
import MultipleIndustries from "./imgs/landing-website/key-features/multiple-industries.svg";
import OneClickMagic from "./imgs/landing-website/key-features/one-click-magic.svg";
import SinglePayment from "./imgs/landing-website/key-features/single-payment.svg";
import StudioQuality from "./imgs/landing-website/key-features/studio-quality.svg";

import GridImg1 from "./imgs/landing-website/visual-stories/grid-img1.webp";
import GridImg2 from "./imgs/landing-website/visual-stories/grid-img2.webp";
import GridImg3 from "./imgs/landing-website/visual-stories/grid-img3.webp";
import GridImg4 from "./imgs/landing-website/visual-stories/grid-img4.webp";

import Amazon from "./imgs/landing-website/visual-stories/amazon.webp";
import Facebook from "./imgs/landing-website/visual-stories/facebook.webp";
import Shopify from "./imgs/landing-website/visual-stories/shopify.webp";
import TikTok from "./imgs/landing-website/visual-stories/tik-tok.webp";
import WooCommerce from "./imgs/landing-website/visual-stories/woo-commerce.webp";
import CompatibleMac from "./imgs/landing-website/instant-access/compatible-mac.svg";
import CompatibleWindows from "./imgs/landing-website/instant-access/compatible-windows.svg";

import Visa from "./imgs/landing-website/instant-access/visa.webp";
import Paypal from "./imgs/landing-website/instant-access/paypal.webp";
import Mastercard from "./imgs/landing-website/instant-access/mastercard.webp";
import AmericanExpress from "./imgs/landing-website/instant-access/american-express.webp";
import Discover from "./imgs/landing-website/instant-access/discover.webp";

import LogoIcon from "./imgs/landing-website/logo-icon";

export const ICONS = {
  Close: CloseIcon,
  InfoOutlinedIcon: InfoOutlinedIcon,
  Eye: VisibilityIcon,
  EyeOff: VisibilityOff,
  Email: MailOutlineIcon,
  Edit: EditIcon,
  Delete: DeleteIcon,
  Dashboard: SpaceDashboardIcon,
  Menu: MenuIcon,
  Subject: SubjectIcon,
  Section: AutoAwesomeMosaicIcon,
  MoreVertSharpIcon: MoreVertSharpIcon,
  ArrowDropDownIcon: ArrowDropDownIcon,
  ArrowDropUp: ArrowDropUp,
  CheckCircleIcon: CheckCircleIcon,
  CancelIcon: CancelIcon,
  ResetLoop: ResetLoop,
  Trash: Trash,
  DragIndicatorIcon: DragIndicatorIcon,
  FilterAltIcon: FilterAltIcon,
  Search: SearchIcon,
  ArrowDown: ArrowDropDownIcon,
  MenuBars: MenuOutlinedIcon,
  ArrowBack: ArrowBackIosNewIcon,
  VerifiedUser: VerifiedUserOutlinedIcon,
  East: EastRoundedIcon,
  ArrowForward: ArrowForwardRoundedIcon,
  ArrowDownward: ArrowDownwardRoundedIcon,
  Instagram: InstagramIcon,
  Facebook: FacebookIcon,
  X: XIcon,
  LinkedIn: LinkedInIcon,
  YouTube: YouTubeIcon,
  Visibility: VisibilityRoundedIcon,
  VisibilityOff: VisibilityOffRoundedIcon,
  LogoIcon,
};

export const IMAGES = {
  LandingLogo,
  HeroBackground,
  HeroElementLeft,
  HeroElementRight,
  BannerBg,
  BannerBgLarge,
  SocialProofPeople,
  Traditional,
  ModernAi,
  Logo3d,
};

export const KEY_FEATURES_IMAGES = {
  AiHandle,
  AiMasterpiece,
  MultipleIndustries,
  OneClickMagic,
  SinglePayment,
  StudioQuality,
};

export const VISUAL_STORIES_GRID_IMAGES = {
  GridImg1,
  GridImg2,
  GridImg3,
  GridImg4,
};

export const VISUAL_STORIES_BRANDS_IMAGES = {
  Amazon,
  Facebook,
  Shopify,
  TikTok,
  WooCommerce,
};

export const INSTANT_ACCESS_IMAGES = {
  CompatibleMac,
  CompatibleWindows,
};

export const INSTANT_ACCESS_CARD_BRAND_IMAGES = {
  Visa,
  Paypal,
  Mastercard,
  AmericanExpress,
  Discover,
};
