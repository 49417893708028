import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApi } from "utils/customNetwork";
import moment from "moment";
import defaultImage from "assets/imgs/default/news_default.png";
import "./newsDetail.scss";
import { theme } from "utils/theme";
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

export function NewsDetails() {
  const { id } = useParams();
  const [news, setNews] = useState(null);
  // const { setLoading } = useContext(LayoutContext);

  const [loading, setLoading] = useState();

  useEffect(() => {
    const fetchNewsDetails = async () => {
      setLoading(true);
      try {
        const response = await getApi("/getNewsDetails", "POST", {
          news_id: id,
        });
        console.log(response);
        setNews(response.data);
      } catch (error) {
        console.error("Error fetching news posts:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchNewsDetails();
  }, [id]);

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      {loading && !news ? (
        <Stack
          sx={{
            minHeight: "calc(100vh - 192px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <CircularProgress size="3rem" />
          ) : (
            <Typography
              variant="h4"
              sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
            >
              No Data Found!
            </Typography>
          )}
        </Stack>
      ) : (
        <Stack sx={{ gap: { xs: 4, sm: 6 } }}>
          <Stack sx={{ gap: 2 }}>
            <Typography
              variant="h3"
              sx={{
                background: theme.palette.common.black,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 600,
                fontSize: 45,
                textAlign: "center",
              }}
            >
              {news?.title || "-"}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: theme.palette.grey[500],
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              Published on:{" "}
              <Box component="span" sx={{ color: "#007bff" }}>
                {moment(news?.created_at).format("MMM D, YYYY") || "-"}
              </Box>
            </Typography>
          </Stack>

          <Divider />

          <Box
            component="img"
            src={news?.image || defaultImage}
            alt={news?.title}
            sx={{
              width: "100%",
              aspectRatio: "16/9",
              objectFit: "cover",
              borderRadius: 3,
              maxWidth: "900px",
              alignSelf: "center",
              // maxWidth: "500px",
            }}
          />

          <Divider />

          <Stack>
            <Box
              dangerouslySetInnerHTML={{
                __html: news?.description,
              }}
              sx={{
                p: {
                  color: theme.palette.common.black,
                  fontSize: 18,
                },
              }}
            />
          </Stack>
        </Stack>
      )}
    </Container>
  );
}
