// Faq.js
import React, { useState, useEffect } from "react";
import { getApi } from "utils/customNetwork";
import "./styles.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { theme } from "utils/theme";
import { ICONS, IMAGES } from "assets";

export function Faqs() {
  // const [activeIndex, setActiveIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);

  const [loading, setLoading] = useState();

  const fetchFaqs = async () => {
    try {
      setLoading(true);
      const data = await getApi("/getFaqList", "GET");
      setLoading(false);
      if (data.data !== undefined && data.data.length > 0) {
        setFaqs(data.data);
      } else {
        setFaqs([]);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchFaqs();
  }, []);

  // const toggleFAQ = (index) => {
  //   setActiveIndex(activeIndex === index ? null : index);
  // };
  return (
    <Stack
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          background: `url(${IMAGES.HeroBackground})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          height: "100%",
          width: "100%",
          position: "absolute",
          left: 0,
          right: 0,
          transform: "rotate(180deg);",
          transformOrigin: "center",
        },
      }}
    >
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Stack sx={{ gap: 10 }}>
          <Stack sx={{ gap: 3 }}>
            <Typography
              variant="h3"
              sx={{
                background: theme.palette.primaryGradient.main,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 600,
                fontSize: 45,
                textAlign: "center",
              }}
            >
              Frequently Asked Questions
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.common.black,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Everything You Need to Know Before You Join
            </Typography>
          </Stack>
          {loading && !faqs.length ? (
            <Stack
              sx={{
                minHeight: "calc(100vh - 322px)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <CircularProgress size="3rem" />
              ) : (
                <Typography
                  variant="h4"
                  sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
                >
                  No Data Found!
                </Typography>
              )}
            </Stack>
          ) : (
            <Stack sx={{ gap: 4 }}>
              {faqs.map(({ id, question, answer }) => {
                return (
                  <Accordion
                    key={id}
                    sx={{
                      border: `1px solid ${theme.palette.primary.main}`,
                      borderRadius: "20px !important",
                      "&::before": {
                        height: "unset",
                      },
                      boxShadow: "0px 7px 38px 0px rgba(103, 110, 114, 0.10)",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ICONS.ArrowDownward
                          sx={{ color: theme.palette.primary.main }}
                        />
                      }
                      aria-controls={`panel${id}-content`}
                      id={`panel${id}-header`}
                      sx={{ my: 1.5 }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: theme.palette.primary.extraDark,
                          fontWeight: 600,
                        }}
                      >
                        {question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="subtitle1">{answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
}
