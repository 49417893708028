import React, { useRef, useEffect, useState } from "react";
import { getApi } from "utils/customNetwork";
import "./styles.scss";
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  Avatar,
} from "@mui/material";
import { theme } from "utils/theme";
import { ICONS, IMAGES } from "assets";
import Slider from "react-slick";
import { getFirstTwoLettersCapitalized, lineClamp } from "utils/util";
import KeyFeatures from "./KeyFeatures";
import TraditionalVsAiPowered from "./TraditionalVsAiPowered";
import VisualStories from "./VisualStories";
import InstantAccess from "./InstantAccess";
import { LANDING_THEME } from "utils/landingTheme";

export function LandingPage() {
  const { HEADER_HEIGHT } = LANDING_THEME;

  let sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3500,
    className: "center",
    centerMode: true,
    centerPadding: "30px",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          centerPadding: "20px",
        },
      },
    ],
  };

  const testimonialsRef = useRef(null);
  const scrollIntervalRef = useRef(null);
  const [testimonials, setTestimonials] = useState([]);
  // const { setLoading } = useContext(LayoutContext);

  const autoScroll = () => {
    if (testimonialsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = testimonialsRef.current;

      if (scrollLeft + clientWidth >= scrollWidth) {
        testimonialsRef.current.scrollLeft = 0;
      } else {
        testimonialsRef.current.scrollBy({ left: 300, behavior: "smooth" });
      }
    }
  };

  const loadTestimonials = async () => {
    // setLoading(true);
    try {
      const data = await getApi("/fetchTestimonails", "GET");
      if (data.data && data.data.length > 0) {
        setTestimonials((prevData) => [...prevData, ...data.data]);
      }
    } catch (error) {
      console.error("Error fetching blog posts:", error);
      // setLoading(false);
    } finally {
      // setLoading(false);
    }
  };

  const handleWheelScroll = (event) => {
    event.preventDefault();
    testimonialsRef.current.scrollBy({
      left: event.deltaY < 0 ? -300 : 300,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollIntervalRef.current = setInterval(autoScroll, 5000);
    setTestimonials([]);
    loadTestimonials();

    const testimonialsElement = testimonialsRef.current;
    if (testimonialsElement) {
      testimonialsElement.addEventListener("wheel", handleWheelScroll);
    }

    return () => {
      clearInterval(scrollIntervalRef.current);
      if (testimonialsElement) {
        testimonialsElement.removeEventListener("wheel", handleWheelScroll);
      }
    };
  }, []);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  return (
    <Stack>
      <Stack
        sx={{
          minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${IMAGES.HeroBackground})`,
          backgroundPosition: "bottom",
          position: "relative",
        }}
      >
        <Box
          component="img"
          src={IMAGES.HeroElementLeft}
          alt="HeroElementLeft"
          sx={{ position: "absolute", left: 25, opacity: { xs: 0.2, lg: 1 } }}
        />
        <Box
          component="img"
          src={IMAGES.HeroElementRight}
          alt="HeroElementRight"
          sx={{ position: "absolute", right: 25, opacity: { xs: 0.2, lg: 1 } }}
        />
        <Container maxWidth="xl" sx={{ py: 10, zIndex: 1 }}>
          <Stack
            sx={{ justifyContent: "center", alignItems: "center", gap: 6 }}
          >
            <Typography
              variant="h3"
              sx={{
                color: theme.palette.common.black,
                textAlign: "center",
                fontWeight: 600,
                maxWidth: 1000,
              }}
            >
              Transform Ordinary Product Shots Into A.I. Powered{" "}
              <Box component="span" sx={{ color: theme.palette.primary.dark }}>
                High-Converting Visuals
              </Box>{" "}
              in 3 Clicks
            </Typography>
            <Stack sx={{ alignItems: "center" }}>
              <Typography
                variant="h5"
                sx={{ color: theme.palette.grey[800], textAlign: "center" }}
              >
                Skyrocket Your sales, Traffic, and Customer Engagement
              </Typography>
              <Typography
                variant="h5"
                sx={{ color: theme.palette.grey[800], textAlign: "center" }}
              >
                With{" "}
                <Box
                  component="span"
                  sx={{
                    background: theme.palette.primaryGradient.main,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 600,
                  }}
                >
                  AI-Driven Product Photos in 60 Seconds
                </Box>
              </Typography>
            </Stack>
            <Stack sx={{ alignItems: "center", gap: 0.5 }}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  textTransform: "none",
                  borderRadius: "36.5px",
                  fontWeight: 600,
                  background: theme.palette.secondaryGradient.main,
                  py: 1.5,
                  px: 5,
                  fontSize: 16,
                }}
              >
                Get Creativio AI Right Now
              </Button>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.common.black }}
              >
                Commitment Free : Cancel Anytime!
              </Typography>
            </Stack>
            <Stack sx={{ alignItems: "center", gap: 1 }}>
              <Box
                component="img"
                src={IMAGES.SocialProofPeople}
                alt="SocialProofPeople"
                sx={{ maxHeight: "80px" }}
              />
              <Typography
                variant="h4"
                sx={{
                  background: theme.palette.primaryGradient.main,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  textAlign: "center",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                  }}
                >
                  6734
                </Box>{" "}
                People Can't Be Wrong
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.common.black,
                  textAlign: "center",
                }}
              >
                Let AI Generate Breath-taking Visuals for You Today!
              </Typography>
            </Stack>
          </Stack>
        </Container>
      </Stack>

      <KeyFeatures />

      <VisualStories />

      <TraditionalVsAiPowered />

      <Container maxWidth="xl" sx={{ py: 8 }}>
        <Stack sx={{ gap: 4, justifyContent: "center", alignItems: "center" }}>
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.primary.extraDark,
              fontWeight: 600,
            }}
          >
            What Our Users Say
          </Typography>

          <Stack
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              gap: 2,
              flexWrap: { xs: "wrap", sm: "nowrap" },
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: theme.palette.primaryGradient.main,
                minWidth: "auto",
                p: 1.2,
                borderRadius: "40px",
                order: { xs: 2, sm: 1 },
              }}
              onClick={previous}
            >
              <ICONS.ArrowBack sx={{ fontSize: "20px" }} />
            </Button>
            <Stack
              className="slider-container custom-slider"
              sx={{
                width: { xs: "100%", sm: "calc(100% - 112px)" },
                order: { xs: 1, sm: 2 },
              }}
            >
              <Slider
                ref={(slider) => {
                  sliderRef = slider;
                }}
                {...settings}
              >
                {testimonials.map(
                  ({ client_name, description, image, created_at }, index) => {
                    return (
                      <Stack key={"slide-" + index} sx={{ height: "100%" }}>
                        <Stack sx={{ p: "2px", height: "calc(100% - 4px)" }}>
                          <Stack
                            sx={{
                              gap: 3,
                              justifyContent: "space-between",
                              p: { xs: 3, sm: 4 },
                              background:
                                "linear-gradient(0deg, rgba(115, 113, 252, 0.23) 4%, rgba(177, 109, 246, 0.10) 95%)",
                              // boxShadow: "0px 4px 14px 0px #0000001A",
                              borderRadius: 5,
                              border: `1 px solid ${theme.palette.primary.main}`,
                              height: "100%",
                            }}
                          >
                            <Box
                              dangerouslySetInnerHTML={{ __html: description }}
                              sx={{
                                p: {
                                  color: "black",
                                  ...lineClamp(6),
                                },
                              }}
                            />

                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1.5,
                              }}
                            >
                              <Avatar
                                src={image}
                                alt="User"
                                sx={{
                                  height: { xs: 45, sm: 50 },
                                  width: { xs: 45, sm: 50 },
                                  background:
                                    "linear-gradient(103deg, #563bff 0%, #7371fc 43.6%, #d94fd5 100%)",
                                  p: { xs: 0.4, sm: 0.4 },
                                  img: {
                                    borderRadius: "50%",
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: { xs: 16, sm: 18 },
                                    lineHeight: "unset",
                                  }}
                                >
                                  {getFirstTwoLettersCapitalized(
                                    client_name || ""
                                  )}
                                </Typography>
                              </Avatar>
                              <Stack>
                                <Typography
                                  variant="body1"
                                  sx={{ color: "black" }}
                                >
                                  {client_name || "-"}
                                </Typography>
                                {/* <Typography
                                  variant="caption"
                                  sx={{ color: theme.palette.grey[500] }}
                                >
                                  {created_at
                                    ? moment(created_at).fromNow()
                                    : "-"}
                                </Typography> */}
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    );
                  }
                )}
              </Slider>
            </Stack>
            <Button
              variant="contained"
              sx={{
                background: theme.palette.primaryGradient.main,
                minWidth: "auto",
                p: 1.2,
                borderRadius: "40px",
                transform: "rotate(180deg)",
                order: { xs: 3, sm: 3 },
              }}
              onClick={next}
            >
              <ICONS.ArrowBack sx={{ fontSize: "20px" }} />
            </Button>
          </Stack>
        </Stack>
      </Container>

      <InstantAccess />
    </Stack>
  );
}
