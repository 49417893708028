import { AdminLayout } from "components/layouts/AdminLayout";
import { CButton } from "components/atoms/CButton";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import { useState, useContext, useRef, useEffect } from "react";
import { Slide } from "react-awesome-reveal";
import { getApi } from "utils/customNetwork";
import { CTextarea } from "components/atoms/CTextarea";
import "./styles.scss";
export function AdminHomePage() {
    const fileInputRef = useRef(null);
    const { setLoading, showNoti, checkToken } = useContext(LayoutContext);
    const [terms, setTerms] = useState([]);
    const [newImageURL, setNewImageURL] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [formData, setFormData] = useState({
        bannerSection: {
            bannerMainHeading: "",
            bannerSubHeading: "",
            bannerPeopleHeading: "",
        },
        informationSection: {
            firstInformation: "",
            secondInformation: "",
            thirdInformation: "",
            fourthInformation: "",
            fifthInformation: "",
            sixthInformation: "",
        },
        traditionalPhotographySection: {
            traditionalDetails: "",
        },
        creativioShotsSection: {
            creativioDetails: "",
        },
    });
    const [code, setCode] = useState(``);

    const onUploadAvatar = () => {
        if (!newImage) {
            showNoti("info", "Please upload an image");
            return;
        }
        setNewImage(null);
        setFormData({
            ...formData,
            image: newImage, // Set the new image in formData
        })
    };
    const onImageUpload = () => {
        fileInputRef.current.click();
    };

    const onRemoveAvatar = () => {
        if (newImage || newImageURL) {
            setNewImageURL(null);
            setNewImage(null);
        } else {
            // Remove the image key from the formData object
            setFormData((prevData) => ({
                ...prevData,
                image: null, // Set image to null
            }));

            // If you're updating another state (like blogs) at the same time:
            setTerms((prevNews) => ({
                ...prevNews,
                image: null, // Clear image from blogs
            }));
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setNewImageURL(reader.result);
            };
            setNewImage(file);
            reader.readAsDataURL(file);
        }
    };
    useEffect(() => {
        fetchTerms();
    }, []);

    const fetchTerms = async () => {
        try {
            setLoading(true);
            checkToken();
            const response = await getApi("/admin/getTermsofService");
            if (response?.data) {
                const { description, id, image } = response.data;

                // Ensure each field is not undefined before setting formData
                setFormData({
                    description: description || "",
                    id: id || "",
                    image: image || ""
                });
            }
            setTerms(response.data);
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };


    // const handleEditorChange = (content) => {
    //     setFormData((prevFormData) => ({
    //         ...prevFormData, // Spread the existing formData to retain other fields
    //         description: content || "", // Update only the description field
    //     }));
    //     setCode(content || "");
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split(".");
    
        setFormData((prev) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formToSubmit = new FormData();

        // Append other form fields to FormData
        formToSubmit.append("description", formData.description);
        formToSubmit.append("id", formData.id);

        // Append the image if it exists
        if (formData.image === null || formData.image === "") {
            formToSubmit.append("is_image", 0);
        }
        if (formData.image && typeof formData.image !== 'string') {
            formToSubmit.append("image", formData.image);  // Append image file
        }

        let apiUrl = "/admin/updateTermsofService";

        try {
            setLoading(true);
            checkToken();
            const response = await getApi(apiUrl, "POST", formToSubmit);
            if (formData.id) {
                showNoti("success", "Home Page Updated Successfully!");
            } else {
                showNoti("success", "Home Page Added Successfully!");
            }
            fetchTerms();
        } catch (e) {
            showNoti("error", e);
        } finally {
            setLoading(false);
        }
    };
    const shortName = () => {
        // console.log("formData",formData);
        return 'C';
    };

    return (
        <AdminLayout
            page="dashboard"
            caption="Dashboard"
            message="Welcome back admin"
        >
            <div className="admin-home-page body-container">
                <div className="head">
                    <div className="header-testimonial">
                        <Slide className="flex1">
                            <h1>
                                <span>Home Page</span>
                            </h1>
                        </Slide>
                    </div>

                    <div className="mail-settings settings">
                      <form onSubmit={handleSubmit}>
                        <div className="section-container">
                            <h3 className="section-title">Banner Section</h3>
                            <div className="main-body-container">
                                <div className="form-group mt-20">
                                    <label htmlFor="banner_main_heading">Banner Main Heading:</label>
                                    <CTextarea
                                    id="banner_main_heading"
                                    name="bannerSection.banner_main_heading"
                                    addClass="banner_main_heading"
                                    placeholder="Banner Main Heading"
                                    value={formData.bannerSection.banner_main_heading}
                                    onChange={handleChange}
                                    rows="2" required
                                    > </CTextarea>
                                </div>

                                <div className="form-group mt-20">
                                    <label htmlFor="banner_sub_heading">Banner Sub Heading:</label>
                                    <CTextarea
                                    id="banner_sub_heading"
                                    name="bannerSection.banner_sub_heading"
                                    addClass="banner_sub_heading"
                                    placeholder="Banner Sub Heading:"
                                    value={formData.bannerSection.banner_sub_heading}
                                    onChange={handleChange}
                                    rows="2" required
                                    > </CTextarea>
                                </div>

                                <div className="form-group mt-20">
                                    <label htmlFor="banner_people_heading">Banner People Heading:</label>
                                    <CTextarea
                                    id="banner_people_heading"
                                    name="bannerSection.banner_people_heading"
                                    addClass="banner_people_heading"
                                    placeholder="Banner People Heading"
                                    value={formData.bannerSection.banner_people_heading}
                                    onChange={handleChange}
                                    rows="2" required
                                    > </CTextarea>
                                </div>
                            </div>
                        </div>

                        <div className="section-container">
                            <h3 className="section-title">Information Section</h3>
                            <div className="main-body-container">
                                <div className="mt-20">
                                  <label htmlFor="first_information">First Information details:</label>
                                    <input
                                        type="text"
                                        id="first_information"
                                        name="informationSection.first_information"
                                        placeholder="First Information"
                                        value={formData.informationSection.first_information}
                                        onChange={handleChange}
                                        required
                                        step="0.01"
                                    />
                                </div>

                                <div className="mt-20">
                                  <label htmlFor="second_information">Second Information details:</label>
                                    <input
                                        type="text"
                                        id="second_information"
                                        name="informationSection.second_information"
                                        placeholder="Second Information"
                                        value={formData.informationSection.second_information}
                                        // onChange={handleChange}
                                        required
                                        step="0.01"
                                    />
                                </div>
                            </div>
                            <div className="main-body-container">
                                <div className="mt-20">
                                  <label htmlFor="third_information">Third Information details:</label>
                                    <input
                                        type="text"
                                        id="third_information"
                                        name="informationSection.third_information"
                                        placeholder="Third Information"
                                        value={formData.informationSection.third_information}
                                        onChange={handleChange}
                                        required
                                        step="0.01"
                                    />
                                </div>

                                <div className="mt-20">
                                  <label htmlFor="fourth_information">Fourth Information details:</label>
                                    <input
                                        type="text"
                                        id="fourth_information"
                                        name="fourth_information"
                                        placeholder="Fourth Information"
                                        value={formData.informationSection.fourth_information}
                                        onChange={handleChange}
                                        required
                                        step="0.01"
                                    />
                                </div>
                            </div>
                            <div className="main-body-container">
                                <div className="mt-20">
                                  <label htmlFor="fifth_information">Fifth Information details:</label>
                                  <input
                                        type="text"
                                        id="fifth_information"
                                        name="fifth_information"
                                        placeholder="Fifth Information"
                                        value={formData.informationSection.fifth_information}
                                        onChange={handleChange}
                                        required
                                        step="0.01"
                                    />
                                </div>

                                <div className="mt-20">
                                  <label htmlFor="sixth_information">Sixth Information details:</label>
                                    <input
                                        type="text"
                                        id="sixth_information"
                                        name="sixth_information"
                                        placeholder="Sixth Information"
                                        value={formData.informationSection.sixth_information}
                                        onChange={handleChange}
                                        required
                                        step="0.01"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="section-container">
                            <h3 className="section-title">Traditional Photography Section</h3>
                            <div className="main-body-container">
                                <div className="mt-20">
                                  <label htmlFor="traditional_details">Traditional Details:</label>
                                  <CTextarea
                                      id="traditional_details"
                                      name="traditional_details"
                                      addClass="traditional_details"
                                      placeholder="Traditional Details"
                                      value={formData.traditionalPhotographySection.traditional_details}
                                      onChange={handleChange}
                                      rows="2" required
                                    > </CTextarea>
                                </div>

                            </div>
                        </div>

                        <div className="section-container">
                            <h3 className="section-title">Creativio Shots Section</h3>
                            <div className="main-body-container">
                                <div className="mt-20">
                                  <label htmlFor="creativio_details">Creativio Details:</label>
                                  <CTextarea
                                      id="creativio_details"
                                      name="creativio_details"
                                      addClass="creativio_details"
                                      placeholder="Creativio Details"
                                      value={formData.creativioShotsSection.creativio_details}
                                      onChange={handleChange}
                                      rows="2" required
                                    > </CTextarea>
                                </div>
                            </div>
                        </div>
                        <button className="custom-button primary active mt-25" type="submit">Submit</button>               
                      </form>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}
