// PrivacyPolicy.js
import { React, useEffect, useState } from "react";
import { getApi } from "utils/customNetwork";
import "./styles.scss";
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { theme } from "utils/theme";
import { IMAGES } from "assets";

export function PrivacyPolicy() {
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState();

  const fetchPrvacyPolicy = async () => {
    try {
      setLoading(true);
      const data = await getApi("/fetchPricvacyPolicy", "GET");
      setLoading(false);
      if (data.data !== undefined && data.data != null) {
        setData(data.data);
      } else {
        setData(null); // Ensure faqs is an empty array if no data
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    setData(null);
    fetchPrvacyPolicy();
  }, []);
  return (
    <Stack>
      <Stack
        sx={{
          background: `url(${IMAGES.BannerBgLarge})`,
          minHeight: { xs: "160px", sm: "250px" },
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="h2"
            sx={{
              color: "white",
              fontWeight: 600,
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            Privacy Policy
          </Typography>
        </Container>
      </Stack>

      {loading && !data?.description ? (
        <Stack
          sx={{
            minHeight: "calc(100vh - 325px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <CircularProgress size="3rem" />
          ) : (
            <Typography
              variant="h4"
              sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
            >
              No Data Found!
            </Typography>
          )}
        </Stack>
      ) : (
        <Container maxWidth="xl" sx={{ py: 8 }}>
          <Box
            dangerouslySetInnerHTML={{
              __html: data?.description,
            }}
            sx={{
              p: {
                color: theme.palette.common.black,
                fontSize: 18,
              },
            }}
          />
        </Container>
      )}
    </Stack>
  );
}
