import { CButton } from "components/atoms/CButton";
import { useContext, useState } from "react";
import { getApi } from "utils/customNetwork";
import { LayoutContext } from "components/contexts/LayoutContextContainer";
import "./styles.scss";
import logo from "assets/imgs/logo.svg";
import { ReactComponent as Man } from "assets/imgs/auth/people.svg";
import SocialLinks from "./SocialLinks";
import { IMAGES } from "assets";
import { Box, Container, Stack } from "@mui/material";

export function ForgotPassword() {
  const { setLoading, showNoti } = useContext(LayoutContext);
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    setLoading(true);
    try {
      await getApi("/forgot-password", "POST", { email });
      setIsSent(true);
    } catch (err) {
      showNoti("error", err);
    }
    setLoading(false);
  };

  return (
    <Box
      id="#login"
      className="auth-area"
      sx={{ minHeight: "calc(100vh - 75px)" }}
    >
      <Container maxWidth="md" sx={{ pt: 5, pb: 3 }}>
        <div
          className="login"
          style={{ background: `url(${IMAGES.BannerBg})` }}
        >
          <Stack
            sx={{ p: 3, width: "calc(100% - 48px)", alignItems: "center" }}
          >
            <Stack sx={{ width: "100%", maxWidth: "400px" }}>
              <Stack
                className="logo mt-15 d-flex justify-content-center"
                sx={{ flexDirection: "row", justifyContent: "center" }}
              >
                <img src={logo} alt="logo" />
              </Stack>
              <div className="">
                {!isSent && (
                  <form onSubmit={handleSubmit}>
                    <div className="login-card mt-35">
                      <div className="form-group">
                        <div className="input-with-icon">
                          <Man className="icon-img" />
                          <input
                            type="email"
                            id="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="mt-20 mb-60">
                        <CButton
                          addClass="primary text-size-20 py-10 full large"
                          mainClass="w-full"
                          type="submit"
                        >
                          Forgot Password
                        </CButton>
                      </div>
                    </div>
                  </form>
                )}
                {isSent && (
                  <div className="my-60 text-center">
                    Reset password link sent to your email. Please check your
                    inbox.
                  </div>
                )}
              </div>
            </Stack>
            <SocialLinks />
          </Stack>
        </div>
      </Container>
    </Box>
  );
}
