import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import defaultImage from "assets/imgs/default/blog_default.jpg";
import { getApi } from "utils/customNetwork";
import moment from "moment";
import "./blogDetail.scss";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { theme } from "utils/theme";
import { ICONS } from "assets";
import { PAGES } from "utils/urls";

export function BlogDetail() {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  // const { setLoading } = useContext(LayoutContext);
  const [loading, setLoading] = useState();

  useEffect(() => {
    const fetchBlogDetails = async () => {
      setLoading(true);
      try {
        const response = await getApi("/getBlogDetails", "POST", { slug });
        setBlog(response.data);
        setPrevious(response.previous);
        setNext(response.next);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogDetails();
  }, [slug, setLoading]);

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      {loading && !blog ? (
        <Stack
          sx={{
            minHeight: "calc(100vh - 192px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <CircularProgress size="3rem" />
          ) : (
            <Typography
              variant="h4"
              sx={{ color: theme.palette.grey[400], fontWeight: 600 }}
            >
              No Data Found!
            </Typography>
          )}
        </Stack>
      ) : (
        <Stack sx={{ gap: { xs: 4, sm: 6 } }}>
          <Stack sx={{ gap: 2 }}>
            <Typography
              variant="h3"
              sx={{
                background: theme.palette.common.black,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 600,
                fontSize: 45,
                textAlign: "center",
              }}
            >
              {blog?.title || "-"}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: theme.palette.grey[500],
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              Published on:{" "}
              <Box component="span" sx={{ color: "#007bff" }}>
                {moment(blog?.created_at).format("MMM D, YYYY") || "-"}
              </Box>
            </Typography>
          </Stack>

          <Divider />

          <Box
            component="img"
            src={blog?.image || defaultImage}
            alt={blog?.title}
            sx={{
              width: "100%",
              aspectRatio: "16/9",
              objectFit: "cover",
              borderRadius: 3,
              maxWidth: "900px",
              alignSelf: "center",
              // maxWidth: "500px",
            }}
          />

          <Divider />

          <Stack>
            <Box
              dangerouslySetInnerHTML={{
                __html: blog?.description,
              }}
              sx={{
                p: {
                  color: theme.palette.common.black,
                  fontSize: 18,
                },
              }}
            />
          </Stack>

          <Divider />

          <Stack
            sx={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: !previous ? "end" : "space-between",
            }}
          >
            {previous && (
              <Button
                component={Link}
                to={`${PAGES.BLOGS.path}/${previous.slug}`}
                variant="outlined"
                sx={{ fontWeight: 600, textTransform: "none" }}
                startIcon={
                  <ICONS.ArrowBack sx={{ fontSize: "17px !important" }} />
                }
              >
                Previous
              </Button>
            )}

            {next && (
              <Button
                component={Link}
                to={`${PAGES.BLOGS.path}/${next?.slug}`}
                variant="outlined"
                sx={{
                  fontWeight: 600,
                  textTransform: "none",
                  alignSelf: "end",
                }}
                endIcon={
                  <ICONS.ArrowBack
                    sx={{
                      fontSize: "17px !important",
                      transform: "rotate(180deg)",
                    }}
                  />
                }
              >
                Next
              </Button>
            )}
          </Stack>
        </Stack>
      )}
    </Container>
  );
}
