import { Stack } from "@mui/material";
import { ICONS } from "assets";
import React from "react";
import { LANDING_THEME } from "utils/landingTheme";
import { CircularProgress } from "@mui/material";

const LoadingScreen = () => {
  const { HEADER_HEIGHT } = LANDING_THEME;
  return (
    <Stack
      sx={{
        minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <CircularProgress size={110} thickness={1.5} />
      <ICONS.LogoIcon sx={{ position: "absolute" }} />
    </Stack>
  );
};

export default LoadingScreen;
