import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import useToggleState from "hooks/useToggleState";
import { ICONS } from "assets";

const NormalInput = ({ errorMessage, isHideHelperText, ...props }) => {
  return (
    <TextField
      error={Boolean(errorMessage)}
      {...(!isHideHelperText && { helperText: errorMessage })}
      {...props}
    />
  );
};

const ErrorInput = ({ name, control, type, isHideHelperText, ...props }) => {
  const { state, toggle } = useToggleState();

  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorMessage = error?.message || "";

  return (
    <TextField
      {...{ name, value, onBlur }}
      type={type === "password" ? (state ? "text" : "password") : type}
      {...(type === "password" && {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Visibility"
                color="primary"
                onClick={toggle}
              >
                {state ? (
                  <ICONS.VisibilityOff style={{ fontSize: 22 }} />
                ) : (
                  <ICONS.Visibility style={{ fontSize: 22 }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        },
      })}
      error={Boolean(errorMessage)}
      {...(!isHideHelperText && { helperText: errorMessage })}
      onChange={(e) => {
        const { value } = e.target;
        onChange(value);
      }}
      {...props}
      inputRef={ref}
    />
  );
};

const Input = ({
  color = "primary",
  size = "medium",
  fullWidth = true,
  variant = "outlined",
  type = "text",
  name,
  isHideHelperText = false,
  control,
  inputType = "error",
  ...props
}) => {
  return inputType === "error" ? (
    <ErrorInput
      {...{
        color,
        size,
        fullWidth,
        variant,
        type,
        name,
        isHideHelperText,
        control,
      }}
      {...props}
    />
  ) : (
    <NormalInput
      {...{
        color,
        size,
        fullWidth,
        variant,
        type,
        name,
      }}
      {...props}
    />
  );
};

export default Input;
